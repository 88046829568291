import React from 'react'

export interface IWizListProps<T> {
    data: T[]
    extractKey: (item: T) => string
    renderItem: (item: T) => React.ReactNode
    className?: string
    renderHeader?: () => React.ReactNode
    renderFooter?: () => React.ReactNode
    renderEmpty?: () => React.ReactNode
}

const WizList: React.FC<IWizListProps<any>> = ({
    data = [],
    extractKey,
    className,
    renderHeader,
    renderEmpty,
    renderFooter,
    renderItem,
}) => {
    return (
        <div className={`${className}`}>
            {renderHeader && renderHeader()}
            {data?.length === 0 && renderEmpty && renderEmpty()}
            {data?.map(item => (
                <div key={extractKey(item)}>{renderItem(item)}</div>
            ))}
            {renderFooter && renderFooter()}
        </div>
    )
}

export default WizList
