import React from 'react'

interface IProps {
    className?: string
    sizeClassName?: string
}

const SkeletonBlock: React.FC<IProps> = ({className, sizeClassName}) => {
    return (
        <div role="status" className={`max-w relative h-[15px] ${className}`}>
            <div
                className={`overflow-hidden absolute top-[50%] translate-y-[-50%] left-0 w-full bg-gray07 dark:bg-dark_gray07 rounded-[3px] ${sizeClassName}`}>
                <div
                    className={`relative z-[1] w-[450px] h-[450px] animate-[skeleton_3s_linear_infinite] bg-[url('/images/img_skeleton_bright.webp')] dark:bg-[url('/images/img_skeleton_bright_dark.webp')] bg-cover bg-center bg-no-repeat`}
                />
            </div>
        </div>
    )
}

export default SkeletonBlock
