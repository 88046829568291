import React from 'react'
import {ListboxButton} from '@headlessui/react'
import IconSelectArrow from '@svg/common/ic_select_arrow.svg'
import {WSOption} from '@component/select/WizSelectBox'
import Text from '@component/Text/Text'
import classNames from 'classnames'

interface IProps {
    className?: string
    disabled?: boolean
    selectedOption: WSOption
    selectedIcon: React.ReactNode
}

const WizRoundSelectBoxButton: React.FC<IProps> = ({className, selectedOption, disabled, selectedIcon}) => {
    return (
        <ListboxButton
            className={classNames(
                'w-full min-h-[45px] flex items-center justify-between px-[15px] gap-[10px] cursor-pointer rounded-full',
                /* disabled */ `${
                    disabled ? 'bg-gray07 dark:bg-dark_gray07' : 'bg-transparent01 dark:bg-bg_dark_white01'
                }`,
                className,
            )}>
            <div className={'flex items-center gap-[10px]'}>
                {selectedIcon}
                <Text
                    className={`text-body2 text-gray01 dark:text-dark_gray01 whitespace-nowrap truncate pt-[3px]`}
                    enablePreWhiteSpace={false}>
                    {selectedOption?.text}
                </Text>
                <Text
                    className={`text-body3 text-gray03 dark:text-dark_gray03 whitespace-nowrap truncate`}
                    enablePreWhiteSpace={false}>
                    {selectedOption?.subText}
                </Text>
            </div>

            <div className={'flex items-center'}>
                <IconSelectArrow
                    className={`w-[16px] ${
                        disabled ? 'fill-gray04 dark:fill-dark_gray04' : 'fill-gray01 dark:fill-dark_gray01'
                    }`}
                />
            </div>
        </ListboxButton>
    )
}
export default WizRoundSelectBoxButton
