import React from 'react'

interface Props {
    className?: string
    children: JSX.Element | JSX.Element[]
}

export const TableHeaderContainer = React.memo(function TableHeader({className, children}: Props) {
    return (
        <div
            className={`py-[15px] px-[20px] bg-gray09 dark:bg-bg_dark_white02 border border-gray07 dark:border-dark_gray07 rounded-[5px] ${className}`}>
            {children}
        </div>
    )
})
