import React from 'react'
import Text from '@component/Text/Text'

interface IProps {
    className?: string
    type?: string
}

const AssetTypeTag: React.FC<IProps> = ({className, type}) => {
    return (
        <div
            className={`ml-[5px] sm:ml-0 bg-gray08 dark:bg-bg_dark_white01 py-[0.5px] px-[5px] rounded-[8px] ${className}`}>
            <Text className={'text-body_comm text-gray02 dark:text-dark_gray02'}>{type}</Text>
        </div>
    )
}

export default AssetTypeTag
