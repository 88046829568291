import React from 'react'

interface IProps {
    text?: string
    placeholder?: string
    className?: string
    disabled?: boolean

    onChanged?(text: string): void
}

// todo 글자수 초과시 TextArea 디자인 요청 (to 수진)

const WizTextArea: React.FC<IProps> = ({text, placeholder, className, disabled, onChanged}) => {
    return (
        <textarea
            className={`bg-white dark:bg-bg_dark_white01 disabled:text-gray04 dark:disabled:text-dark_gray04 disabled:bg-gray07 dark:disabled:bg-dark_gray07 box-border min-h-[83px] w-full text-input text-gray01 dark:text-dark_gray01 resize-none rounded-[5px] border border-gray06 dark:border-dark_gray06 disabled:hover:border-gray06 dark:disabled:hover:border-dark_gray06 hover:border-gray01 dark:hover:border-dark_gray01 focus:border-gray01 dark:focus:border-dark_gray01 placeholder:text-gray03 dark:placeholder:text-dark_gray03 placeholder:disabled:text-gray04 dark:placeholder:disabled:text-dark_gray04 px-[15px] py-[10px] block ${className}`}
            value={text}
            onChange={e => onChanged(e.target.value)}
            placeholder={placeholder}
            disabled={disabled}
        />
    )
}

export default WizTextArea
