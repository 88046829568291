import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react'

import {motion} from 'framer-motion'

import classNames from 'classnames'

import {WSOption} from '@component/select/WizSelectBox'

import WizSegmentedControlHoverItem from '@component/segmentedControl/item/WizSegmentedControlHoverItem'
import Text from '@component/Text/Text'

type SelectorKind = 'gray' | 'faint' | 'clear'
type SelectSize = 'large' | 'medium' | 'small' | 'smallest'
//gray is the one with gray background e.g. asset-summary tags
//faint is the one with white dimmed background e.g. 7D 30D...
//clear is the one with no background at all e.g on profit, balance ...

export interface ISelectOptions extends WSOption {
    renderItem?: JSX.Element
}

interface IProps {
    className?: string
    selectorKind?: SelectorKind
    buttonSize?: SelectSize
    selectedOption: string | number
    options: Array<ISelectOptions>
    onChange?: (value: string | number) => void
    disabled?: boolean
    tabClassName?: string
}

const WizSegmentedControl: React.FC<IProps> = ({
    className,
    selectorKind = 'faint',
    buttonSize = 'small',
    selectedOption,
    options,
    onChange,
    disabled,
    tabClassName,
}) => {
    const navRef = useRef<HTMLDivElement>(null)

    const [buttonRefs, setButtonRefs] = useState<Array<HTMLButtonElement | null>>([])
    const [hoveredTabIndex, setHoveredTabIndex] = useState<number | null>(null)

    useEffect(() => {
        setButtonRefs(prev => prev.slice(0, options.length))
    }, [options.length])

    const buttonPaddingSize = useMemo(() => {
        if (buttonSize === 'large') {
            return 'py-[15px] px-[15px]'
        } else if (buttonSize === 'medium') {
            return 'py-[11.5px] px-[15px]'
        } else if (buttonSize === 'small') {
            return 'py-[5.5px] px-[15px]'
        } else {
            return 'py-[3px] px-[14px]'
        }
    }, [buttonSize])

    const tagClassName = useCallback(
        isSelected => {
            if (isSelected) {
                const className = `bg-white dark:bg-bg_dark_white02 dark:text-dark_gray01 rounded-full ${
                    disabled ? 'cursor-default' : 'cursor-pointer shadow-normal'
                } ${buttonPaddingSize} `
                if (selectorKind === 'clear') {
                    return className + 'ring-[1px]  ring-gray07 dark:ring-dark_gray07 ring-inset'
                }
                return className
            }

            return `dark:bg-transparent rounded-full ${
                disabled ? 'cursor-default' : 'cursor-pointer'
            } ring-transparent ${buttonPaddingSize}`
        },
        [selectorKind, disabled, buttonPaddingSize],
    )

    const textClassName = useCallback(
        isSelected => {
            if (isSelected) {
                switch (selectorKind) {
                    case 'gray':
                        return 'text-ti3'
                    case 'clear':
                        return 'text-ti2 sm:text-ti3'
                    case 'faint':
                        return 'text-ti2 sm:text-ti3'
                    default:
                        return 'text-ti3 py-[11.5px]'
                }
            }

            switch (selectorKind) {
                case 'gray':
                    return `text-ti3 ${
                        disabled ? 'text-gray04 dark:text-dark_gray04' : 'text-gray03 dark:text-dark_gray03'
                    }`
                case 'clear':
                    return `text-ti2 ${
                        disabled ? 'text-gray04 dark:text-dark_gray04' : 'text-gray02 dark:text-dark_gray02'
                    } sm:text-ti3 `
                case 'faint':
                    return `text-ti2 ${
                        disabled ? 'text-gray04 dark:text-dark_gray04' : 'text-gray03 dark:text-dark_gray03'
                    } sm:text-ti3 `
                default:
                    return `text-ti2 ${
                        disabled ? 'text-gray04 dark:text-dark_gray04' : 'text-gray03 dark:text-dark_gray03'
                    } `
            }
        },
        [selectorKind, disabled],
    )

    const bgStyle = useMemo(() => {
        switch (selectorKind) {
            case 'gray':
                return 'bg-transparent01 dark:bg-bg_dark_white01 p-[3px] gap-[3px]'
            case 'clear':
                return 'bg-opacity-0 gap-[5px]'
            case 'faint':
                return `${
                    disabled
                        ? 'bg-gray08 dark:bg-dark_gray08'
                        : 'bg-white bg-opacity-50 dark:bg-bg_dark_white02 dark:bg-opacity-100'
                } ring-[1px] ring-gray07 dark:ring-dark_gray07 p-[5px] gap-[5px]`
        }
    }, [selectorKind, disabled])

    return (
        <nav
            ref={navRef}
            className={classNames('relative flex flex-shrink-0 items-center rounded-full', bgStyle, className)}
            onPointerLeave={e => setHoveredTabIndex(null)}>
            {options?.map((option, i) => {
                const itemClassName = tagClassName(option?.id === selectedOption)
                const tcn = textClassName(option?.id === selectedOption)
                const getRenderRightAreaStyle = option?.renderRightArea ? 'flex items-center gap-x-[5px]' : ''
                return (
                    <motion.button
                        key={i}
                        className={classNames(
                            'justify-center relative flex items-center z-20 cursor-pointer select-none transition-all duration-150 ease-in-out',
                            itemClassName,
                            getRenderRightAreaStyle,
                            tabClassName,
                        )}
                        ref={el => (buttonRefs[i] = el)}
                        onPointerEnter={() => !disabled && setHoveredTabIndex(i)}
                        onFocus={() => !disabled && setHoveredTabIndex(i)}
                        onClick={() => !disabled && onChange(option?.id)}>
                        <Text
                            className={classNames('window:pt-[1px] whitespace-nowrap', tcn)}
                            enablePreWhiteSpace={false}>
                            {option?.text}
                        </Text>
                        {option?.renderItem && option?.renderItem}
                        {option?.renderRightArea && option?.renderRightArea}
                    </motion.button>
                )
            })}
            {!disabled && (
                <WizSegmentedControlHoverItem
                    navRef={navRef}
                    buttonRefs={buttonRefs}
                    hoveredTabIndex={hoveredTabIndex}
                    selectedOption={selectedOption}
                    options={options}
                />
            )}
        </nav>
    )
}

export default WizSegmentedControl
