import React, {useState} from 'react'
import Text from '@component/Text/Text'
import {useRouter} from 'next/router'
import Link from 'next/link'
import IconNext from '@svg/common/ic_btn_next.svg'
import IconPlus from '@svg/common/ic_btn_add.svg'

interface IProps {
    text: string
    href: string
    textClassName?: string
    containerClassName?: string
    renderArrow?: boolean
    renderPlus?: boolean
    onClick?: (event: React.MouseEvent<HTMLAnchorElement | MouseEvent>) => void
    disabled?: boolean
    iconClassName?: string
    lineClassName?: string
}

const WizLink: React.FC<IProps> = ({
    text,
    href,
    textClassName,
    containerClassName,
    renderArrow,
    renderPlus,
    disabled,
    iconClassName,
    lineClassName,
}) => {
    const router = useRouter()

    const [isHover, setIsHover] = useState(false)

    return (
        <Link
            suppressHydrationWarning={true}
            href={href}
            className={`relative ${disabled ? '' : 'hover:bg-primary_tint01 dark:hover:bg-dark_primary_tint01'}`}
            onClick={e => disabled && e?.cancelable && e?.preventDefault()}
            onMouseEnter={() => {
                setIsHover(true)
            }}
            onMouseLeave={() => {
                setIsHover(false)
            }}>
            <div className={`flex items-center ${containerClassName}`}>
                {renderPlus && (
                    <IconPlus
                        className={`w-[12px] relative top-[-1px] ${
                            disabled ? 'fill-gray04 dark:fill-dark_gray04' : 'fill-gray02 dark:fill-dark_gray02'
                        } ${iconClassName}`}
                    />
                )}
                <Text
                    className={`text-body2 ${
                        disabled ? 'text-gray04 dark:text-dark_gray04' : 'text-gray02 dark:text-dark_gray02'
                    } ${textClassName}`}>
                    {text}
                </Text>
                {renderArrow && (
                    <IconNext
                        className={`w-[12px] ${
                            disabled ? 'fill-gray04 dark:fill-dark_gray04' : 'fill-gray02 dark:fill-dark_gray02'
                        } ${iconClassName}`}
                    />
                )}
            </div>
            <div
                className={`w-full h-[1px] absolute bottom-0 left-0 ${
                    disabled ? 'bg-gray04 dark:bg-dark_gray04' : 'bg-gray02 dark:bg-dark_gray02'
                } ${isHover || disabled ? 'block' : 'hidden'} ${lineClassName}`}
            />
        </Link>
    )
}

export default WizLink
