type WebSocketEventHandler = {
    onOpen?: () => void
    onMessage?: (data: any) => void
    onClose?: () => void
    onError?: (error: Error) => void
}

export type WebSocketConfig = {
    url: string
    protocols?: string | string[]
    handlers?: WebSocketEventHandler
}

class WebSocketManager {
    private static instance: WebSocketManager
    private webSocketClients = new Map<
        string,
        {
            socket: WeakRef<WebSocket>
            config: WebSocketConfig
        }
    >()

    private constructor() {}

    static getInstance(): WebSocketManager {
        if (!this.instance) {
            this.instance = new WebSocketManager()
        }
        return this.instance
    }

    public async createWebSocket(key: string, config: WebSocketConfig): Promise<WebSocket> {
        this.closeWebSocket(key)

        const ws = new WebSocket(config.url, config.protocols)

        ws.onopen = () => {
            console.log(`WebSocket connected: ${key}`)
            config.handlers?.onOpen?.()
        }

        ws.onmessage = event => {
            try {
                config.handlers?.onMessage?.(event.data)
            } catch (error) {
                console.error(`WebSocket message error: ${key}`, error)
            }
        }

        ws.onclose = event => {
            console.log(`WebSocket closed: ${key}`, event.reason)
            config.handlers?.onClose?.()
        }

        ws.onerror = error => {
            console.error(`WebSocket error: ${key}`, error)
            config.handlers?.onError?.(new Error('WebSocket error'))
        }

        this.webSocketClients.set(key, {
            socket: new WeakRef(ws),
            config,
        })

        return ws
    }

    public async sendMessage(key: string, data: any) {
        try {
            const clientInfo = this.webSocketClients.get(key)
            const socket = clientInfo?.socket.deref()

            if (socket?.readyState === WebSocket.OPEN) {
                socket.send(JSON.stringify(data))
            } else {
                console.warn(`WebSocket ${key} is not in OPEN state. Current state: ${socket?.readyState}`)
            }
        } catch (error) {
            console.error('WebSocket send error:', error)
        }
    }

    public isWebSocketConnected(key: string): boolean {
        const clientInfo = this.webSocketClients.get(key)
        const socket = clientInfo?.socket.deref()
        return socket ? socket.readyState === WebSocket.OPEN : false
    }

    public closeWebSocket(key: string): void {
        const ws = this.webSocketClients.get(key)
        if (ws) {
            ws.socket.deref()?.close()
            this.webSocketClients.delete(key)
        }
    }

    public closeAllWebSockets(): void {
        this.webSocketClients.forEach((ws, key) => {
            ws.socket.deref().close()
        })
        this.webSocketClients.clear()
    }
}

export const webSocketManager = WebSocketManager.getInstance()
