import React from 'react'
import Text from '@component/Text/Text'
import {useTranslation} from 'next-i18next'
import IconCheck from '@svg/common/ic_check.svg'

interface IProps {}

const ReferralTag: React.FC<IProps> = ({}) => {
    const {t} = useTranslation()
    return (
        <div
            className={
                'flex items-center space-x-[3px] rounded-full bg-primary_tint01 dark:bg-dark_primary_tint01 pl-[10px] pr-[5px] py-[2.5px]'
            }>
            <Text
                className={
                    'text-body3 text-primary_shade dark:text-dark_primary_shade pt-[2px] font-bold whitespace-nowrap'
                }
                enablePreWhiteSpace={false}>
                {t('invoice.ReferralTag.type.title')}
            </Text>
            <IconCheck className={'w-[14px] flex-none fill-primary_shade dark:fill-dark_primary_shade sm:opacity-40'} />
        </div>
    )
}
export default ReferralTag
