import React from 'react'
import Text from '@component/Text/Text'
import {useTranslation} from 'next-i18next'

interface IProps {
    className?: string
}

const CopyLeaderTag: React.FC<IProps> = ({className}) => {
    const {t} = useTranslation()

    return (
        <Text
            className={`flex justify-center items-center rounded-[13px] px-[10px] py-[5.5px] text-ti4 text-brown_shade dark:text-dark_brown_shade bg-brown_tint01 dark:bg-dark_brown_tint01 whitespace-nowrap ${className}`}
            enablePreWhiteSpace={false}>
            {t('modal.searchUser.item.leader.badge.text')}
        </Text>
    )
}

export default CopyLeaderTag
