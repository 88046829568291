import React from 'react'
import Text from '@component/Text/Text'
import {useTranslation} from 'next-i18next'

interface IProps {
    className?: string
    size?: 'normal' | 'small'
}

const ProTag: React.FC<IProps> = ({className, size = 'normal'}) => {
    const {t} = useTranslation()

    return (
        <div className={`${className} flex items-center justify-center`}>
            {size === 'normal' && (
                <div className={'rounded-[5px] bg-gradient_pro px-[10px] py-[3px]'}>
                    <Text className={'text-body3 font-bold text-white whitespace-nowrap'} enablePreWhiteSpace={false}>
                        {t('accountPro.currentState.proBadge')}
                    </Text>
                </div>
            )}

            {size === 'small' && (
                <div className={'rounded-[2px] bg-gradient_pro px-[5px]'}>
                    <Text
                        className={'text-[9px] leading-[15px] font-bold text-white whitespace-nowrap'}
                        enablePreWhiteSpace={false}>
                        {t('accountPro.currentState.proBadge')}
                    </Text>
                </div>
            )}
        </div>
    )
}

export default ProTag
