import React from 'react'
import {ListboxButton} from '@headlessui/react'
import IconSelectArrow from '@svg/common/ic_select_arrow.svg'
import WizImage from '@component/images/WizImage'
import {SelectBoxSizeType, WSOption} from '@component/select/WizSelectBox'
import Text from '@component/Text/Text'
import classNames from 'classnames'

interface IProps {
    className?: string
    open: boolean
    disabled?: boolean
    selectedOption: WSOption
    size?: SelectBoxSizeType
}

const WizSelectBoxButton: React.FC<IProps> = ({className, open, selectedOption, disabled, size}) => {
    const getSizeStyle = () => {
        switch (size) {
            case 'small':
                return 'min-h-[39px] text-body2'
            case 'medium':
                return 'min-h-[45px] text-body1'
        }
    }

    return (
        <ListboxButton
            className={classNames(
                `w-full ${getSizeStyle()} flex items-center justify-between px-[15px] gap-[10px] border cursor-pointer rounded-[3px]`,
                /* hover */ ' hover:shadow-normal dark:hover:shadow-dark_normal hover:disabled:shadow-none hover:disabled:dark:shadow-none hover:border-gray01 hover:disabled:border-gray06 dark:hover:border-dark_gray01 dark:hover:disabled:border-dark_gray06',
                /* disabled */ `${disabled ? 'bg-gray07 dark:bg-dark_gray07' : 'bg-white dark:bg-bg_dark_white01'}`,
                /* open */ `${
                    open
                        ? 'border-gray01 shadow-normal dark:shadow-dark_normal dark:border-dark_gray01'
                        : 'border-gray06 dark:border-dark_gray06'
                }`,
                className,
            )}>
            <div className={'flex items-center gap-[10px] truncate'}>
                <WizImage
                    src={selectedOption?.icon}
                    containerClassName={`sm:flex-none ${disabled && 'opacity-[0.5] dark:opacity-[0.5]'}`}
                    width={20}
                    height={20}
                />
                <Text
                    className={` ${
                        disabled ? 'text-gray04 dark:text-dark_gray04' : 'text-gray01 dark:text-dark_gray01'
                    } whitespace-nowrap truncate`}
                    enablePreWhiteSpace={false}>
                    {selectedOption?.text}
                </Text>
                {selectedOption?.subText && (
                    <Text
                        className={`text-body3 text-gray03 dark:text-dark_gray03 text-[12px] font-normal whitespace-nowrap truncate`}
                        enablePreWhiteSpace={false}>
                        {selectedOption?.subText}
                    </Text>
                )}
            </div>

            <div className={'flex items-center'}>
                <IconSelectArrow
                    className={`w-[16px] ${
                        disabled ? 'fill-gray04 dark:fill-dark_gray04' : 'fill-gray01 dark:fill-dark_gray01'
                    }`}
                />
            </div>
        </ListboxButton>
    )
}
export default WizSelectBoxButton
