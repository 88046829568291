import React from 'react'
import {ListboxOption} from '@headlessui/react'
import {WSOption} from '@component/select/WizSelectBox'
import WizImage from '@component/images/WizImage'
import Text from '@component/Text/Text'
import classNames from 'classnames'

interface IProps {
    className?: string
    option: WSOption
    selectedOption: string | number
    onClick?: () => void
}

const WizSelectBoxItem: React.FC<IProps> = ({className, option, selectedOption, onClick}) => {
    const isSelected = selectedOption === option.id
    return (
        <ListboxOption
            value={selectedOption}
            onClick={onClick}
            className={classNames(
                'cursor-pointer text-body1',
                /* light */ 'text-gray02 hover:text-gray01 hover:bg-gray07 ',
                /* dark */ 'dark:text-dark_gray02 dark:hover:text-dark_gray01 dark:hover:bg-dark_gray07',
            )}>
            <div className={'flex items-center gap-[10px] px-[15px] py-[12px]'} data-value={option.id || 'default'}>
                <WizImage
                    src={option?.icon}
                    containerClassName={`w-[20px] flex items-center flex-none`}
                    width={20}
                    height={20}
                />
                <Text
                    className={`tracking-normal whitespace-nowrap sm:truncate ${
                        isSelected && 'text-ti1 text-primary_shade dark:text-dark_primary_shade'
                    }`}
                    enablePreWhiteSpace={false}>
                    {option?.text}
                </Text>
                {option?.subText && (
                    <Text
                        className={`text-body3 tracking-normal whitespace-nowrap ${
                            isSelected && 'text-ti3 text-primary_shade dark:text-dark_primary_shade'
                        }`}
                        enablePreWhiteSpace={false}>
                        {option.subText}
                    </Text>
                )}
            </div>
        </ListboxOption>
    )
}
export default React.memo(WizSelectBoxItem)
