import React, {useEffect, useRef, useState} from 'react'
import {Tab} from '@headlessui/react'
import {equalString} from '@util/strings'

export type WizToggleOptions = {
    id: string
    text: string
    value?: any
}

interface IProps {
    options: Array<WizToggleOptions>
    defaultId?: string
    className?: string
    onChanged?: (item: WizToggleOptions) => void
    dataCy?: string
}

const WizToggle: React.FC<IProps> = ({options, className, onChanged, defaultId, dataCy}) => {
    const [selectTab, setSelectTab] = useState(options.indexOf(options?.find(item => item.id === defaultId)) ?? 0)

    const isInitDefaultOption = useRef(false)

    useEffect(() => {
        if (isInitDefaultOption.current === false && options.length > 0 && defaultId) {
            const defaultOption = options.find(item => equalString(item.id, defaultId))
            setSelectTab(options.indexOf(defaultOption))
            onChanged(defaultOption)

            isInitDefaultOption.current = true
        } else {
            const option = options.find(item => equalString(item?.id, options?.at(selectTab)?.id))
            if (option) {
                setSelectTab(options.indexOf(option))
                onChanged(option)
            } else {
                if (options?.length > 0) {
                    setSelectTab(options.indexOf(options[0]))
                    onChanged(options[0])
                }
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [options, defaultId])

    useEffect(() => {
        if (onChanged) {
            onChanged(options[selectTab])
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectTab])

    const getTabStyle = (isSelected?: boolean) => {
        const textStyle = `${
            isSelected ? 'text-primary_shade dark:text-dark_primary_shade' : 'text-gray03 dark:text-dark_gray03'
        }`
        const ringStyle = `ring-inset ${
            isSelected
                ? 'ring-1 ring-primary dark:ring-dark_primary shadow-normal'
                : 'text-gray03 dark:text-dark_gray03'
        }`
        const backgroundStyle = `${isSelected ? 'bg-primary_tint02 dark:bg-dark_primary_tint02' : ''}`
        return `text-ti2 font-bold  rounded-[3px] py-[12px] px-[20px] flex-1 ${textStyle} ${ringStyle} ${backgroundStyle}`
    }

    return (
        <div className={`${className}`} data-cy={dataCy}>
            <Tab.Group selectedIndex={selectTab} onChange={setSelectTab}>
                <Tab.List
                    className={
                        'flex rounded-[3px] gap-[1px] ring-1 ring-inset ring-gray05 dark:ring-dark_gray05 overflow-hidden bg-white dark:bg-bg_dark_white02'
                    }>
                    {options.map(tl => (
                        <Tab
                            key={tl.id}
                            value={tl.value}
                            className={({selected}) => getTabStyle(selected)}
                            data-value={tl.id}>
                            {tl.text}
                        </Tab>
                    ))}
                </Tab.List>
            </Tab.Group>
        </div>
    )
}

export default WizToggle
