import React, {useMemo} from 'react'
import {WSOption} from '@component/select/WizSelectBox'
import {Listbox, ListboxOptions} from '@headlessui/react'
import WizSelectBoxItem from '@component/select/item/WizSelectBoxItem'
import classNames from 'classnames'
import WizRoundSelectBoxButton from '@component/select/item/WizRoundSelectBoxButton'
import {AnimatePresence, motion} from 'framer-motion'

interface IProps {
    className?: string
    buttonClassName?: string
    optionClassName?: string
    disabled?: boolean
    selectedOption: string
    options?: Array<WSOption>
    onChange?: (option?: string | number) => void
    selectedIcon: React.ReactNode
}

const WizRoundSelectBox: React.FC<IProps> = ({
    className,
    buttonClassName,
    optionClassName,
    disabled,
    selectedOption,
    options,
    onChange,
    selectedIcon,
}) => {
    const selectedOptionItem = useMemo(() => {
        return options?.find(option => option.id === selectedOption)
    }, [options, selectedOption])
    return (
        <div className={`relative min-w-[100px] ${className}`}>
            <Listbox value={selectedOption} disabled={disabled}>
                {({open}) => {
                    return (
                        <div>
                            <WizRoundSelectBoxButton
                                className={buttonClassName}
                                disabled={disabled}
                                selectedOption={selectedOptionItem}
                                selectedIcon={selectedIcon}
                            />

                            <AnimatePresence>
                                {open && (
                                    <ListboxOptions
                                        anchor={'bottom'}
                                        static
                                        modal={false}
                                        as={motion.div}
                                        initial={{opacity: 0}}
                                        animate={{opacity: 1}}
                                        exit={{opacity: 0}}
                                        className={classNames(
                                            'w-[var(--button-width)] [--anchor-gap:5px]',
                                            'origin-top z-dropdown max-h-[315px] overflow-y-auto rounded-[3px] border',
                                            /* light */ 'shadow-normal bg-white border-gray06',
                                            /* dark */ 'dark:shadow-dark_normal dark:bg-bg_dark_popup dark:border-dark_gray06',
                                            optionClassName,
                                        )}>
                                        {options?.map(option => (
                                            <WizSelectBoxItem
                                                key={option.id}
                                                option={option}
                                                selectedOption={selectedOption}
                                                onClick={() => onChange(option?.id)}
                                            />
                                        ))}
                                    </ListboxOptions>
                                )}
                            </AnimatePresence>
                        </div>
                    )
                }}
            </Listbox>
        </div>
    )
}
export default WizRoundSelectBox
