import React, {useRef} from 'react'
import Text from '@component/Text/Text'
import useFormatNumber from '@hook/useFormatNumber'

interface IProps {
    leverage?: number
    side?: string
    cross_margin?: boolean
    className?: string
}

const LeverageTag: React.FC<IProps> = ({leverage, side, cross_margin = false, className}) => {
    const {displayLeverage} = useFormatNumber()

    const getBackgroundColor = () => {
        switch (side) {
            case 'short':
                return 'bg-red dark:bg-dark_red'
            default:
                return 'bg-primary_shade dark:bg-dark_primary_shade'
        }
    }

    const renderPosition = () => {
        switch (side) {
            case 'short':
                return ' S'
            case 'long':
                return ' L'
            default:
                return ''
        }
    }

    const getText = () => {
        if (cross_margin === true) {
            return `Cx${renderPosition()}`
        } else {
            return `${displayLeverage(leverage)}x${renderPosition()}`
        }
    }

    const ref = useRef<HTMLDivElement>()
    if (!side) return null

    return (
        <div
            ref={ref}
            className={`rounded-full py-[0.5px] px-[5px] window:h-[17px] truncate relative ${getBackgroundColor()} ${className}`}>
            <Text
                className={'text-body_comm text-white leading-[15px] relative top-[0.5px] window:top-[0px]'}
                enablePreWhiteSpace={false}>
                {getText()}
            </Text>
        </div>
    )
}

export default LeverageTag
