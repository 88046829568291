import React from 'react'
import Text from '@component/Text/Text'
import {useTranslation} from 'next-i18next'

interface IProps {
    risk?: number
    className?: string
}

const getBackgroundColor = (risk: number) => {
    switch (risk) {
        case 1:
            return 'text-blue dark:text-dark_blue'
        case 2:
            return 'text-green dark:text-dark_green'
        case 3:
            return 'text-yellow dark:text-dark_yellow'
        case 4:
            return 'text-orange dark:text-dark_orange'
        case 5:
            return 'text-red dark:text-dark_red'
        default:
            return ''
    }
}

const RiskTag: React.FC<IProps> = ({risk, className}) => {
    const {t} = useTranslation()

    const renderText = (risk: number) => {
        switch (risk) {
            case 1:
                return t('riskTag.low')
            case 2:
                return t('riskTag.normal')
            case 3:
                return t('riskTag.high')
            case 4:
                return t('riskTag.veryHigh')
            case 5:
                return t('riskTag.dangerous')
            default:
                return ''
        }
    }

    return (
        <div className={`rounded-full ${className}`}>
            <Text
                className={`text-ti3 font-bold leading-[22px] ${getBackgroundColor(risk)} whitespace-nowrap`}
                enablePreWhiteSpace={false}>
                {renderText(risk)}
            </Text>
        </div>
    )
}

export default RiskTag
