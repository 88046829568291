import React from 'react'
import Text from '@component/Text/Text'
import {useTranslation} from 'next-i18next'

interface IProps {
    className?: string
}

const CopyableTag: React.FC<IProps> = ({className}) => {
    const {t} = useTranslation()

    return (
        <Text
            className={`flex justify-center items-center rounded-[10px] px-[7px] py-[2px] text-ti4 text-white bg-primary dark:bg-dark_primary whitespace-nowrap ${className}`}
            enablePreWhiteSpace={false}>
            {t('copyableTag.text')}
        </Text>
    )
}

export default CopyableTag
