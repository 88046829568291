import React, {Fragment} from 'react'
import {Dialog, Transition} from '@headlessui/react'

interface IProps {
    className?: string
    isOpen?: boolean
    onClick?: (e?: React.MouseEvent<HTMLDivElement>) => void
    onClose?: () => void
    children?: React.ReactNode
}

const WizModal: React.FC<IProps> = ({className, isOpen, onClick, onClose, children}) => {
    return (
        <Dialog open={isOpen} as="div" className="relative z-modal" unmount={true} onClose={onClose}>
            <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0">
                <div className="fixed inset-0 bg-black bg-opacity-40 backdrop-blur-[3px]" />
            </Transition.Child>

            <div className={`fixed inset-0 overflow-y-auto`}>
                <div className={`flex flex-col min-h-full items-center justify-center`} onClick={onClick}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95">
                        <Dialog.Panel className={`bg-white dark:bg-bg_dark_popup ${className}`}>
                            <div>{children}</div>
                        </Dialog.Panel>
                    </Transition.Child>
                </div>
            </div>
        </Dialog>
    )
}
export default WizModal
