import React from 'react'
import SkeletonBlock from '@component/skeleton/SkeletonBlock'

interface IProps {
    className?: string
    length?: number
}

const SkeletonLeagueSideList: React.FC<IProps> = ({className, length}) => {
    return Array.from({length: length ?? 30}, (_, i) => i)?.map(item => (
        <div key={item} className={`flex cursor-pointer py-[15px] px-[20px] gap-x-[10px] items-center rounded-[5px]`}>
            <SkeletonBlock className={'w-[16px] h-[16px]'} sizeClassName={'h-[16px] !rounded-[5px]'} />
            <SkeletonBlock className={'w-[150px] h-[21px]'} sizeClassName={'h-[16px]'} />
        </div>
    ))
}

export default SkeletonLeagueSideList
