import React, {useMemo} from 'react'

import {AnimatePresence, motion} from 'framer-motion'

import {WSOption} from '@component/select/WizSelectBox'

const transition = {
    type: 'tween',
    ease: 'easeOut',
    duration: 0.15,
}
interface IProps {
    className?: string
    navRef: React.MutableRefObject<HTMLDivElement>
    buttonRefs: Array<HTMLButtonElement | null>
    hoveredTabIndex?: number
    selectedOption?: string | number
    options: Array<WSOption>
}

const WizSegmentedControlHoverItem: React.FC<IProps> = ({
    className,
    navRef,
    buttonRefs,
    hoveredTabIndex,
    selectedOption,
    options,
}) => {
    const navRect = navRef.current?.getBoundingClientRect()

    const selectedTabIndex = useMemo(
        () => options.findIndex(option => option.id === selectedOption),
        [selectedOption, options],
    )
    const hoveredRect = buttonRefs[hoveredTabIndex ?? -1]?.getBoundingClientRect()
    return (
        <AnimatePresence>
            {hoveredRect && navRect && (
                <motion.div
                    key={'hover'}
                    className={'absolute z-10 top-0 left-0 rounded-full bg-transparent01 dark:bg-bg_dark_white01'}
                    initial={{
                        x: hoveredRect.left - navRect.left,
                        y: hoveredRect.top - navRect.top,
                        width: hoveredRect.width,
                        height: hoveredRect.height,
                        opacity: 0,
                    }}
                    animate={{
                        x: hoveredRect.left - navRect.left,
                        y: hoveredRect.top - navRect.top,
                        width: hoveredRect.width,
                        height: hoveredRect.height,
                        opacity: selectedTabIndex !== hoveredTabIndex ? 1 : 0,
                    }}
                    exit={{
                        x: hoveredRect.left - navRect.left,
                        y: hoveredRect.top - navRect.top,
                        width: hoveredRect.width,
                        height: hoveredRect.height,
                        opacity: 0,
                    }}
                    transition={transition}
                />
            )}
        </AnimatePresence>
    )
}
export default WizSegmentedControlHoverItem
