import React from 'react'
import Text from '@component/Text/Text'
import {useTranslation} from 'next-i18next'

interface IProps {
    type?: string
}

const OrderTypeTag: React.FC<IProps> = ({type}) => {
    const {t} = useTranslation()

    const getStyleBackground = () => {
        switch (type) {
            case 'buy':
            case 'deposit':
                return 'bg-primary_tint01 dark:bg-dark_primary_tint01'
            case 'sell':
            case 'withdraw':
                return 'bg-red_tint01 dark:bg-dark_red_tint01'
        }
    }

    const getStyleTextColor = () => {
        switch (type) {
            case 'buy':
            case 'deposit':
                return 'text-primary_shade dark:text-dark_primary_shade'
            case 'sell':
            case 'withdraw':
                return 'text-red_shade dark:text-dark_red_shade'
        }
    }

    const renderText = () => {
        switch (type) {
            case 'buy':
                return t('historyTag.buy')
            case 'sell':
                return t('historyTag.sell')
            case 'withdraw':
                return t('historyTag.withdraw')
            case 'deposit':
                return t('historyTag.deposit')
        }
    }

    return (
        <div className={`flex items-center ${getStyleBackground()} rounded-[3px] px-[8px] min-w-[24px] min-h-[24px]`}>
            <Text className={`${getStyleTextColor()} text-ti3`}>{renderText()}</Text>
        </div>
    )
}

export default OrderTypeTag
