import React from 'react'
import Text from '@component/Text/Text'
import {useTranslation} from 'next-i18next'
import IconClose from '@svg/common/ic_close.svg'
import IconCheck from '@svg/common/ic_check.svg'

type keyStatusType = 'valid' | 'invalid'

interface IProps {
    status: keyStatusType
    className?: string
}

const KeyValidTag: React.FC<IProps> = ({status, className}) => {
    const {t} = useTranslation()

    const renderText = () => {
        switch (status) {
            case 'valid':
                return t('keyValidTag.valid')
            case 'invalid':
                return t('keyValidTag.invalid')
        }
    }

    const renderBorderStyle = () => {
        switch (status) {
            case 'valid':
                return 'border border-transparent02 dark:border-bg_dark_white01'
            case 'invalid':
                return 'border border-gray07 dark:border-bg_dark_white01'
        }
    }
    const renderTextStyle = () => {
        switch (status) {
            case 'valid':
                return 'text-gray01 dark:text-dark_gray01'
            case 'invalid':
                return 'text-red_shade dark:text-dark_red_shade'
        }
    }

    return (
        <div
            className={`flex justify-center items-center rounded-[14px] px-[10px] py-[5px] bg-white dark:bg-bg_dark_white03 whitespace-nowrap ${className} space-x-[5px] ${renderBorderStyle()}`}>
            {status === 'valid' && <IconCheck className={'w-[12px] flex-none fill-primary dark:fill-dark_primary'} />}
            {status === 'invalid' && <IconClose className={'w-[12px] fill-red dark:fill-dark_red flex-none'} />}
            <Text className={`text-body_comm font-bold ${renderTextStyle()}`} enablePreWhiteSpace={false}>
                {renderText()}
            </Text>
        </div>
    )
}

export default KeyValidTag
