import React, {useContext, useEffect, useRef, useState} from 'react'
import {
    arrow,
    autoUpdate,
    flip,
    FloatingArrow,
    offset,
    shift,
    useClick,
    useDismiss,
    useFloating,
    useInteractions,
    useRole,
} from '@floating-ui/react'
import {TipContext} from '@component/tooltip/TipContext'
import {equalString} from '@util/strings'
import {useIsVisible} from '@hook/useIsVisible'
import {v4 as uuidv4} from 'uuid'
import IconClose from '@svg/common/ic_close.svg'
import {createLog} from '@util/logs'

type BG_TYPE = 'primary' | 'blue' | 'leader' | 'red'

interface IProps {
    type: string
    placement?: 'top' | 'bottom'
    className?: string
    panelClassName?: string
    onClick?: (e: any) => void
    title?: string
    contents?: string
    bgColor?: BG_TYPE
    render?: JSX.Element
}

const ARROW_HEIGHT = 7
const GAP = 5

const WizTipAutoOpen = (props: IProps) => {
    const {type, placement, className, title, contents, render} = props
    const [id] = useState(uuidv4())
    const [isOpen, setIsOpen] = useState(false)
    const arrowRef = useRef(null)
    const {x, y, strategy, refs, context} = useFloating({
        open: isOpen,
        onOpenChange: setIsOpen,
        placement: placement,
        middleware: [
            flip(),
            shift(),
            offset(ARROW_HEIGHT + GAP),
            arrow({
                element: arrowRef,
            }),
        ],
        whileElementsMounted: autoUpdate,
    })

    const click = useClick(context)
    const dismiss = useDismiss(context, {
        outsidePress: event => {
            popTip(type)
            return true
        },
    })
    const role = useRole(context)
    const {getReferenceProps, getFloatingProps} = useInteractions([click, dismiss, role])

    const onClickButton = () => {
        if (isOpen) {
            createLog('event', 'auto_tooltip_open', {type})
            setIsOpen(false)
            popTip(type)
        } else {
            createLog('event', 'auto_tooltip_close', {type})
            setIsOpen(true)
        }
    }

    const onClickClosePopover = () => {
        setIsOpen(false)
        popTip(type)
    }

    const {registerTip, popTip, nextOpenTip} = useContext(TipContext)

    const ref = useRef()
    const isVisible = useIsVisible(ref)

    useEffect(() => {
        if (isVisible) {
            registerTip(type, id)
        }
    }, [type, id, isVisible, registerTip])

    useEffect(() => {
        setIsOpen(equalString(type, nextOpenTip?.type) && equalString(id, nextOpenTip?.id))
    }, [type, nextOpenTip, id])

    return (
        <div ref={ref} className={`${className}`}>
            <div
                ref={refs?.setReference}
                {...getReferenceProps()}
                className={`${className} cursor-pointer`}
                onClick={(e: any) => {
                    onClickButton()
                    e.stopPropagation()
                }}>
                {render}
            </div>
            {isOpen && (
                <div
                    ref={refs?.setFloating}
                    style={{
                        position: strategy,
                        top: y ?? 0,
                        left: x ?? 0,
                        width: 'max-content',
                    }}
                    {...getFloatingProps()}
                    className={'z-tooltip cursor-pointer'}
                    onClick={e => {
                        createLog('event', 'auto_tooltip_close', {type})
                        onClickClosePopover()
                        e.stopPropagation()
                    }}>
                    <div className={`bg-blue dark:bg-dark_blue p-[10px] rounded-[5px] max-w-[250px]`}>
                        <div className={`flex items-center justify-between relative ${contents && 'mb-[5px]'}`}>
                            <div className={'text-ti2 text-white font-sans'}>{title}</div>
                            <IconClose className={'w-[16px] fill-white ml-[5px]'} />
                        </div>
                        <div className={'text-body3 text-white'}>{contents}</div>
                    </div>
                    <FloatingArrow ref={arrowRef} context={context} className={'fill-blue dark:fill-dark_blue'} />
                </div>
            )}
        </div>
    )
}

export default WizTipAutoOpen
