import React, {useMemo} from 'react'
import WizSelectBox, {WSOption} from '@component/select/WizSelectBox'
import useQueryCurrencys from '@hook/query/core/currency/useQueryCurrencys'
import {createLog} from '@util/logs'
import {useCurrencyStore} from '@store/CurrencyStore'
import {shallow} from 'zustand/shallow'
import useDidMountEffect from '@hook/UseDidMountEffect'

interface IProps {
    onChanged: (value: WSOption) => void
    className?: string
    selectedSymbol?: string
}

const CurrencySelect: React.FC<IProps> = ({onChanged, className, selectedSymbol}) => {
    const {data: currencies} = useQueryCurrencys(true)
    const {selectedSymbol: usedSymbol} = useCurrencyStore(
        state => ({
            selectedSymbol: state.selectedSymbol,
        }),
        shallow,
    )

    const options = useMemo(() => {
        return (
            currencies?.map(item => {
                return {
                    id: item?.symbol?.toLowerCase(),
                    text: item?.name,
                    value: item,
                }
            }) ?? []
        )
    }, [currencies])

    const onChangeCurrency = (id: string) => {
        createLog('event', 'portfolio_edit_avg_price_modal_currency_change', {currency: id})
        onChanged(options.find(item => item.id === id) as WSOption)
    }

    useDidMountEffect(() => {
        onChanged(options.find(item => item.id === usedSymbol) as WSOption)
    }, [usedSymbol, options])

    return (
        currencies && (
            <WizSelectBox
                className={'min-w-[50px]'}
                selectedOption={selectedSymbol}
                options={options}
                onChange={onChangeCurrency}
            />
        )
    )
}

export default CurrencySelect
