import React, {useEffect, useRef, useState} from 'react'
import {usePrevious} from '@hook/usePrevious'
import {IWizTableProps} from '@component/tables/WizTable/WizTable'

const DEFAULT_CELL_COLOR = 'bg-white dark:bg-bg_dark_white01 border-transparent01 dark:border-bg_dark_white01'

interface IWizTableCellProps {
    item: any
    renderLeftPadding?: () => void
    renderRightPadding?: () => void
}

const WizTableCell: React.FC<Omit<IWizTableProps<any>, 'data' | 'extractKey'> & IWizTableCellProps> = ({
    item,
    columns,
    onClickRow,
    itemRowClassName,
    renderLeftPadding,
    renderRightPadding,
    enableCellBgAnimation,
    renderType,
}) => {
    const timerRef = useRef(null)
    const previousPnlValue = usePrevious(item.pnl)
    const [cellBgColor, setCellBgColor] = useState(DEFAULT_CELL_COLOR)

    useEffect(() => {
        if (previousPnlValue === item.pnl || item.is_base || !enableCellBgAnimation) return

        if (previousPnlValue < item.pnl) {
            setCellBgColor(
                'bg-primary_tint01 dark:bg-dark_primary_tint01 border-transparent01 dark:border-bg_dark_white01',
            )
        }
        if (previousPnlValue > item.pnl) {
            setCellBgColor('bg-red_tint01 dark:bg-dark_red_tint01 border-transparent01 dark:border-bg_dark_white01')
        } else {
            setCellBgColor(DEFAULT_CELL_COLOR)
        }
        timerRef.current = setTimeout(() => {
            setCellBgColor(DEFAULT_CELL_COLOR)
        }, 500)
    }, [previousPnlValue, enableCellBgAnimation, item])

    useEffect(() => {
        return () => clearTimeout(timerRef.current)
    }, [timerRef])

    return (
        <tr
            onClick={() => onClickRow && onClickRow(item)}
            className={` ${
                onClickRow && 'hover:bg-gray09 dark:hover:bg-dark_gray09 cursor-pointer '
            } border-b last:border-none ${
                itemRowClassName && itemRowClassName(item)
            } transition-all duration-300 ease-in-out ${cellBgColor}`}>
            <td key={'first flex-none'} className={`${renderLeftPadding()}`}></td>
            {columns.map(({key, header, cell, visibilityClassName}) => (
                <td key={key} className={`${visibilityClassName} `}>
                    {cell(item)}
                </td>
            ))}
            <td key={'last flex-none'} className={`${renderRightPadding()}`}></td>
        </tr>
    )
}

export default WizTableCell
