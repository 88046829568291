import React, {useMemo} from 'react'
import {Listbox, ListboxOptions} from '@headlessui/react'
import WizSelectBoxButton from '@component/select/item/WizSelectBoxButton'
import WizSelectBoxItem from '@component/select/item/WizSelectBoxItem'
import classNames from 'classnames'
import {AnimatePresence, motion} from 'framer-motion'

export interface WSOption {
    id: string | number
    text?: string
    subText?: string
    icon?: string
    value?: any
    renderRightArea?: JSX.Element
}

export type SelectBoxSizeType = 'small' | 'medium'

interface IProps {
    className?: string
    buttonClassName?: string
    optionClassName?: string
    disabled?: boolean
    selectedOption: string | number
    options?: Array<WSOption>
    onChange?: (option?: string | number) => void
    renderRightArea?: JSX.Element
    dataCy?: string
    size?: SelectBoxSizeType
}

const WizSelectBox: React.FC<IProps> = ({
    className,
    buttonClassName,
    optionClassName,
    disabled,
    selectedOption,
    options,
    onChange,
    renderRightArea,
    dataCy,
    size = 'medium',
}) => {
    const selectedOptionItem = useMemo(() => {
        return options?.find(option => option.id === selectedOption)
    }, [options, selectedOption])
    return (
        <div className={`relative min-w-[100px] ${className}`} data-cy={dataCy}>
            <Listbox value={selectedOption} disabled={disabled}>
                {({open}) => {
                    return (
                        <div className={'relative'}>
                            <WizSelectBoxButton
                                className={buttonClassName}
                                open={open}
                                disabled={disabled}
                                selectedOption={selectedOptionItem}
                                size={size}
                            />
                            <AnimatePresence>
                                {open && (
                                    <ListboxOptions
                                        anchor={'bottom start'}
                                        static
                                        modal={false}
                                        as={motion.div}
                                        initial={{opacity: 0}}
                                        animate={{opacity: 1}}
                                        exit={{opacity: 0}}
                                        className={classNames(
                                            'w-[var(--button-width)] [--anchor-gap:5px]',
                                            'absolute origin-top z-dropdown !max-h-[270px] overflow-y-auto rounded-[3px] border',
                                            /* light */ 'shadow-normal bg-white border-gray06',
                                            /* dark */ 'dark:shadow-dark_normal dark:bg-bg_dark_popup dark:border-dark_gray06',
                                            optionClassName,
                                        )}>
                                        {options?.map(option => (
                                            <WizSelectBoxItem
                                                key={option.id}
                                                option={option}
                                                selectedOption={selectedOption}
                                                onClick={() => onChange(option?.id)}
                                            />
                                        ))}
                                    </ListboxOptions>
                                )}
                            </AnimatePresence>
                        </div>
                    )
                }}
            </Listbox>
            {renderRightArea && renderRightArea}
        </div>
    )
}
export default WizSelectBox
