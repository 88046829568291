import React from 'react'
import {Switch} from '@headlessui/react'

interface IProps {
    className?: string
    width?: number
    checked?: boolean
    onChanged?: (checked: boolean) => void
    disabled?: boolean
}

const padding = 4
const WizSwitch: React.FC<IProps> = ({className, width = 48, checked = false, onChanged, disabled = false}) => {
    const checkedTranslateX = width / 2 + padding
    return (
        <Switch
            checked={checked}
            style={{width: width}}
            onChange={onChanged}
            disabled={disabled}
            className={`${
                disabled
                    ? 'bg-gray07 dark:bg-dark_gray07 border border-gray06 dark:border-dark_gray06'
                    : checked
                    ? 'bg-primary dark:bg-dark_primary'
                    : 'bg-gray05 dark:bg-dark_gray05'
            }
            relative inline-flex h-[24px] box-border shrink-0 items-center cursor-pointer rounded-full duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75
            ${className}`}>
            <span
                style={{
                    transform: disabled
                        ? `translateX(${checkedTranslateX}px)`
                        : checked
                        ? `translateX(${checkedTranslateX}px)`
                        : `translateX(${padding}px)`,
                }}
                className={
                    'pointer-events-none inline-block h-[16px] w-[16px] transform rounded-full bg-white shadow-normal ring-0 transition duration-200 ease-in-out'
                }
            />
        </Switch>
    )
}

export default WizSwitch
