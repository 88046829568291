import React, {useState} from 'react'
import {
    autoUpdate,
    flip,
    offset,
    Placement,
    shift,
    useClick,
    useDismiss,
    useFloating,
    useInteractions,
    useRole,
} from '@floating-ui/react'
import IconClose from '@svg/common/ic_close.svg'
import {createLog} from '@util/logs'

type BG_TYPE = 'primary' | 'blue' | 'leader' | 'red'

interface IProps {
    className?: string
    type: string
    panelClassName?: string
    onClick?: (e: any) => void
    title?: string
    render?: JSX.Element
    children?: any
    plusX?: number
    plusY?: number
    placement?: Placement
}

const ARROW_HEIGHT = 7
const GAP = 5

const WizTooltip = (props: IProps) => {
    const {className, type = '', title, children, render, plusX, plusY, panelClassName, placement} = props
    const [isOpen, setIsOpen] = useState(false)
    const {x, y, strategy, refs, context} = useFloating({
        open: isOpen,
        onOpenChange: setIsOpen,
        middleware: [placement ? undefined : flip(), shift(), offset(ARROW_HEIGHT + GAP)],
        whileElementsMounted: autoUpdate,
        placement: placement ?? undefined,
    })

    const click = useClick(context)
    const dismiss = useDismiss(context)
    const role = useRole(context)
    const {getReferenceProps, getFloatingProps} = useInteractions([click, dismiss, role])

    const onClickButton = (e: React.MouseEvent<HTMLDivElement>) => {
        createLog('event', 'tooltip_click_icon', {type})
        setIsOpen(!isOpen)
        e.stopPropagation()
    }

    const onClickClose = (e: React.MouseEvent<HTMLDivElement>) => {
        // createLog('event', 'tooltip_click_close', {type})
        setIsOpen(false)
        e.stopPropagation()
    }

    return (
        <div className={`${className}`}>
            <div
                ref={refs?.setReference}
                {...getReferenceProps()}
                className={`${className} cursor-pointer`}
                onClick={onClickButton}>
                {render}
            </div>
            {isOpen && (
                <div
                    ref={refs?.setFloating}
                    style={{
                        position: strategy,
                        top: plusY ? plusY + y : y ?? 0,
                        left: plusX ? plusX + x : x ?? 0,
                        width: 'max-content',
                    }}
                    {...getFloatingProps()}
                    className={'z-tooltip cursor-pointer'}
                    onClick={onClickClose}>
                    <div
                        className={`bg-white dark:bg-bg_dark_popup p-[20px] rounded-[5px] max-w-[340px] border border-gray05 dark:border-dark_gray05 shadow-normal ${panelClassName}`}>
                        <div className={'flex items-center mb-[10px] justify-between '}>
                            <div className={'text-ti2 text-gray01 dark:text-dark_gray01'}>{title}</div>
                            <IconClose className={'w-[16px] fill-gray02 dark:fill-dark_gray02'} />
                        </div>
                        {children}
                    </div>
                </div>
            )}
        </div>
    )
}

export default WizTooltip
