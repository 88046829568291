import React from 'react'
import Text from '@component/Text/Text'
import {IWeightedReturnPeriod} from '@feature/portfolio/pages/keyLeaderfolio/LeaderfolioGraphContainer/LeaderfolioGraphContainer'

interface IProps {
    className?: string
    options?: IWeightedReturnPeriod[]
    onClick?: (e?: any) => void
    selectItem?: IWeightedReturnPeriod
}

const WizRoundedSegmentedControl: React.FC<IProps> = ({className, options, onClick, selectItem}) => {
    return (
        <div
            className={`bg-white dark:bg-bg_dark_white01 rounded-[3px] ring-[1px] ring-gray05 dark:ring-dark_gray05 ring-inset relative ${className}`}>
            <div className={'flex'}>
                {options?.map((item, idx) => {
                    return (
                        <div
                            key={item?.id}
                            onClick={() => {
                                !item?.disabled && onClick(item)
                            }}
                            className={'relative md:grow text-center py-[10px]'}>
                            <Text
                                className={`text-ti2 px-[15px] ${
                                    idx === 0 && 'border-none'
                                } border-l border-l-transparent02 dark:border-l-bg_dark_white01 ${
                                    item?.disabled
                                        ? 'text-gray05 dark:text-dark_gray05 cursor-default '
                                        : selectItem?.id === item?.id
                                        ? 'text-primary_shade dark:text-dark_primary_shade cursor-pointer '
                                        : 'text-gray03 dark:text-dark_gray03 cursor-pointer '
                                }  relative z-[1]`}>
                                {item?.text}
                            </Text>
                            {selectItem?.id === item?.id && (
                                <div
                                    className={
                                        'ring-[1px] ring-inset ring-primary dark:ring-dark_primary bg-primary_tint02 dark:bg-dark_primary_tint02 absolute top-0 left-0 h-full w-full rounded-[3px]'
                                    }
                                />
                            )}
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default WizRoundedSegmentedControl
