import React from 'react'
import Text from '@component/Text/Text'
import ThreeColorStack from '@feature/portfolio/components/ThreeColorStack/ThreeColorStack'
import {useTranslation} from 'next-i18next'

interface IProps {
    className?: string
    riskLevel: number
}

const RiskLevelTag: React.FC<IProps> = ({className, riskLevel}) => {
    const {t} = useTranslation()
    return (
        <>
            <div
                className={`bg-white dark:bg-bg_dark_white02 px-[7px] py-[3px] flex gap-x-[4px] items-center rounded-[3px] relative ${className}`}>
                <Text className={'text-body3 text-gray02 dark:text-dark_gray02 relative top-[1.5px]'}>
                    {t('leaderboard.table.title.risk_level')}
                </Text>
                <ThreeColorStack
                    charge_low={riskLevel >= 0}
                    charge_fair={riskLevel >= 1}
                    charge_high={riskLevel >= 2}
                    color_low={'bg-primary dark:bg-dark_primary'}
                    color_fair={'bg-yellow dark:bg-dark_yellow'}
                    color_high={'bg-red dark:bg-dark_red'}
                />
            </div>
        </>
    )
}

export default RiskLevelTag
