import React, {useEffect, useState} from 'react'
import {useTranslation} from 'next-i18next'
import Text from '@component/Text/Text'
import IconDown from '@svg/common/ic_btn_down.svg'
import WizTableCell from '@component/tables/WizTable/WizTableCell'

export interface IWizTableProps<T> {
    columns: IWizTableColumn<T>[]
    extractKey: (item: T) => string
    data: T[]
    onClickRow?: (item: T) => void
    header?: IWizTableHeader
    className?: string
    renderHeader?: () => React.ReactNode
    renderEmpty?: () => React.ReactNode
    renderType?: 'default' | 'copyfolio'
    onClick?: () => void
    enableOnClick?: boolean
    hideTable?: boolean
    hoverBorderClassName?: string
    backgroundColorClassName?: string
    itemRowClassName?: (item: T) => string
    count?: number
    onClickViewAll?: () => void
    enableCellBgAnimation?: boolean
    tableClassName?: string
}

export interface IWizTableColumn<T> {
    key: string
    header: () => React.ReactNode
    visibilityClassName?: string
    thClassName?: string
    cell: (item: T) => React.ReactNode
}

export interface IWizTableHeader {
    bgClassName?: string
    paddingHorizontal?: number
    borderClassName?: string
}

const WizTable: React.FC<IWizTableProps<any>> = ({
    data = [],
    columns,
    extractKey,
    onClickRow,
    header,
    className,
    renderHeader,
    renderEmpty,
    renderType,
    itemRowClassName,
    onClick,
    enableOnClick = false,
    hoverBorderClassName = 'hover:border-primary dark:hover:border-dark_primary',
    backgroundColorClassName = 'bg-white dark:bg-transparent',
    hideTable = false,
    count = 0,
    onClickViewAll,
    tableClassName,
    enableCellBgAnimation,
}) => {
    const {t} = useTranslation()
    const [tableColumns, setTableColumns] = useState<IWizTableColumn<any>[]>(columns)
    const [viewCount, setViewCount] = useState(count)

    useEffect(() => {
        setTableColumns(columns)
    }, [columns])

    const renderHeaderBgColor = () => {
        if (header.bgClassName) {
            return `${header.bgClassName}`
        } else return ''
    }

    const renderLeftPadding = () => {
        if (header.paddingHorizontal) {
            return `w-[${header.paddingHorizontal}px]`
        } else return ''
    }

    const renderRightPadding = () => {
        if (header.paddingHorizontal) {
            return `w-[${header.paddingHorizontal}px]`
        } else return ''
    }

    const renderHeaderBorder = () => {
        if (header.borderClassName) {
            return `${header.borderClassName}`
        } else return ''
    }

    const onClickTable = () => {
        if (!enableOnClick) return

        onClick()
    }

    return (
        <div
            className={`${backgroundColorClassName} overflow-x-auto ${className} ${
                enableOnClick && onClick ? `${hoverBorderClassName} cursor-pointer` : ''
            }`}
            onClick={onClickTable}>
            {renderHeader && renderHeader()}
            <table
                className={`w-full table-auto table-collapse border-spacing-0 ${tableClassName} ${
                    hideTable === true && 'hidden'
                }`}>
                <thead className={`overflow-hidden ${renderHeaderBorder()} ${renderHeaderBgColor()}`}>
                    <tr>
                        <th key={'first flex-none'} className={`${renderLeftPadding()}`}></th>
                        {tableColumns.map(({key, header, cell, visibilityClassName, thClassName}) => (
                            <th key={key} className={`${visibilityClassName} ${thClassName} py-[7px] flex-1`}>
                                {header()}
                            </th>
                        ))}
                        <th key={'last flex-none'} className={`${renderRightPadding()}`}></th>
                    </tr>
                </thead>
                <tbody>
                    {data?.map((item, index) => {
                        return (
                            <WizTableCell
                                key={index.toString()}
                                item={item}
                                columns={tableColumns}
                                enableCellBgAnimation={enableCellBgAnimation}
                                onClickRow={onClickRow}
                                itemRowClassName={itemRowClassName}
                                renderLeftPadding={renderLeftPadding}
                                renderRightPadding={renderRightPadding}
                                renderType={renderType}
                            />
                        )
                    })}
                </tbody>
            </table>
            {viewCount > 5 && (
                <div
                    className={
                        'py-[12px] border-t border-gray07 dark:border-dark_gray07 flex justify-center items-center gap-x-[10px]'
                    }
                    onClick={e => {
                        e.stopPropagation()
                        setViewCount(0)
                        onClickViewAll()
                    }}>
                    <Text className={'text-ti2 text-gray02 dark:text-dark_gray02'}>
                        {t('userLeaderfolio.table.viewAll')}
                    </Text>
                    <IconDown width={16} className={'fill-gray02 dark:fill-dark_gray02'} />
                </div>
            )}
            {Number(data?.length) === 0 && renderEmpty && renderEmpty()}
        </div>
    )
}

export default WizTable
