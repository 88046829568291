import React from 'react'
import Text from '@component/Text/Text'

interface IProps {
    title?: string
    isSorted?: boolean
    align?: 'left' | 'center' | 'right'
    className?: string
}

const alignList = {
    left: 'justify-start',
    center: 'justify-center',
    right: 'justify-end',
}

const TableHeader: React.FC<IProps> = ({className, title, align = 'left'}) => {
    return (
        <div className={`flex ${alignList[align]} w-full items-center ${className}`}>
            <Text className={'text-ti4 text-gray02 dark:text-dark_gray02 text-left whitespace-nowrap'}>{title}</Text>
        </div>
    )
}

export default React.memo(TableHeader)
